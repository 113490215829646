import { Route, Routes, Navigate } from 'react-router-dom';
import Record from "@/pages/record/index"
import Keyword from "@/pages/keyword/index"
import Detail from "@/pages/record/detail"
import Feedback from "@/pages/feedback/index"
import Account from "@/pages/account/index"
import Home from '@/pages/home/index';
import Share from "@/pages/share/index"
import ShareLink from "@/pages/sharelink/index"
import { AuthProvider } from './components/AuthProvider/Provider';

function RouterContainer() {
  return (
    <Routes>
      <Route path="/share/:id" element={<Share />} />
      <Route
        path="/feedback"
        element={
          <AuthProvider>
            <Feedback />
          </AuthProvider>
        }
      />
      <Route
        path="/"
        element={
          <AuthProvider>
            <Home />
          </AuthProvider>
        }
      >
        <Route path="record" element={<Record />} />
        <Route path="keyword" element={<Keyword />} />
        <Route path="share-link" element={<ShareLink />} />
        <Route path="record/:id" element={<Detail />} />
        <Route path="account" element={<Account />} />
      </Route>
      <Route path="/" element={<Navigate to="record" />} />
      <Route path="*" element={<Navigate to="/record" />} />
    </Routes>
  );
}

export default RouterContainer;
