import { useEffect, useRef } from 'react';
import SubtitleMQTTClient from '@/utils/mqtt';
import { getShareConnect } from '@/api/subtitles';

interface IProps {
  roomId?: string;
  onMessage: (msg: API.TranslateList) => void;
  onLoaded?: () => void;
}

function useShareMqtt({ roomId, onMessage, onLoaded }: IProps) {
  const isDestroyed = useRef(false);
  const clientRef = useRef<SubtitleMQTTClient<API.TranslateList>>();
  const lastFocusTime = useRef<number>(Date.now());

  useEffect(() => {
    if (!roomId) {
      return;
    }

    const onFocus = () => {
      const now = Date.now();

      if (now - 5000 > lastFocusTime.current) {
        lastFocusTime.current = now;

        if (!clientRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          initClient();
        }
      }
    };

    window.addEventListener('focus', onFocus);

    const getMqttInfo = () => getShareConnect(roomId).then((res) => {
      console.log('>> getShareConnect', res);
      if (res.data.status !== 200) {
        throw new Error('getMqttInfo Failed');
      }

      return res.data.data;
    });

    const initClient = () => {
      getMqttInfo().then((res) => {
        if (!res.status || isDestroyed.current) {
          return;
        }

        console.log('MQTT Connecting');

        clientRef.current = new SubtitleMQTTClient<API.TranslateList>({
          roomId,
          info: res,
          getToken: () => getMqttInfo(),
          onMessage: (msg) => {
            console.log('>> onMessage', msg);
            onMessage(msg);
          },
          topics: [res.share_translate_topic],
          onLoaded: () => {
            onLoaded?.();
          },
        });
      });
    };

    initClient();

    // eslint-disable-next-line consistent-return
    return () => {
      isDestroyed.current = true;

      if (clientRef.current) {
        clientRef.current.destroy();
      }

      window.removeEventListener('focus', onFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);
}

export default useShareMqtt;
