import { useTranslation } from 'react-i18next';
import { Avatar} from 'antd';
import { useState,useEffect } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import { useAuthContext } from '@/hooks/auth';
import { getInvitationInfo, getCurrentPlan } from '@/api/subtitles';
import SvgIcon from '@/components/SvgIcon';
import styles from './menu.module.less';
import { FileTextOutlined } from '@ant-design/icons';
import { formatSeconds } from '@/utils/time';

function MenuContent() {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { isLogin, user } = state;
  const [sendTime, setSendTime] = useState<number>(0);
  const [balance, setBalance] = useState('');
  const navigate = useNavigate();
  // const navigation = useNavigation();
  let location = useLocation();
  const getSendTime = async () => {
    const res = await getInvitationInfo();
    setSendTime(res.data.data.durations / 60);
  };

  const getCurrentTime = async () => {
    const res = await getCurrentPlan();

    if (res.data.data) {
      setBalance(formatSeconds(res.data.data.balance));
    }
  };

  useEffect(() => {
    getSendTime();
  }, []);

  useEffect(() => {
    if (isLogin) {
      getCurrentTime();
    }
  }, [isLogin]);

  const onClickToHome = () => {
    window.open(import.meta.env.VITE_FELO_HOME_URL);
  };

  const onclickToFeedback = () => {
    window.open(`${window.location.origin}/feedback`);
  };

  const clickMenu = (item: string) => {
    navigate(`/${item}`);
  };

  const openPricingpage = (e: any) => {
    e.stopPropagation(); // 阻止事件冒泡
    window.open(`${import.meta.env.VITE_FELO_SUBTITLES_URL}/pricing`);
  };
  const toInviteClick = () => {
    window.open(`${import.meta.env.VITE_FELO_SUBTITLES_URL}/invite`);
  };
  const checkLink = (text: string) => {
    return location.pathname.includes(text);
  }

  return (
    <div className={styles.newmenucontent}>
      <div className={styles.downloadInfo} onClick={onClickToHome}>
        <SvgIcon className={styles.icon} name="download-two" />
        <div className={styles.downloadInfoText}>{t('useClient')}</div>
      </div>
      <div className={styles.menuitems}>
        <div onClick={() => clickMenu('record')} className={`${styles.menuItem} ${checkLink('record') ? styles.selectItem : ''}`}>
          <SvgIcon className={styles.icon} name="recordLog" />
          <div className={styles.menuText}>{t('translationRecord')}</div>
        </div>
        <div onClick={() => clickMenu('keyword')} className={`${styles.menuItem} ${checkLink('keyword') ? styles.selectItem : ''}`}>
          <FileTextOutlined className={styles.icon} />
          <div className={styles.menuText}>{t('keyWordList')}</div>
        </div>
        <div onClick={() => clickMenu('share-link')} className={`${styles.menuItem} ${checkLink('share-link') ? styles.selectItem : ''}`}>
          <FileTextOutlined className={styles.icon} />
          <div className={styles.menuText}>{t('shareLink')}</div>
        </div>
      </div>
      <div className={styles.invitecontent}>
        <div className={styles.invitetext}>{t('invitefriend')}</div>
        <div
          className={styles.inviteTimetext}
          dangerouslySetInnerHTML={{ __html: t('earntime', { time: sendTime }) }}
        ></div>
        <div className={styles.inviteButton} onClick={toInviteClick}>
          {t('inviteNow')}{' '}
        </div>
      </div>
      <div className={styles.boderdiv}></div>
      <div className={styles.fottermenu}>
        <div className={styles.menuItem} onClick={onclickToFeedback}>
          <SvgIcon className={styles.icon} name="file-pen" />
          <div className={styles.menuText}>{t('feedback')}</div>
        </div>
        {/* <div className={styles.menuItem}  onClick={()=>clickMenu('Setting')}>
            <SvgIcon className={styles.icon} name="record" />
            <div>{t('setting')}</div></div> */}
        <div className={styles.menuItem} onClick={onClickToHome}>
          <SvgIcon className={styles.icon} name="grip" />
          <div className={styles.menuText}>{t('moreProducts')}</div>
        </div>
      </div>
      <div className={styles.boderdiv}></div>
      <div
        onClick={() => clickMenu('account')}
        className={`${styles.usercontent} ${checkLink('account') ? styles.selectItem : ''}`}
      >
        <div className={styles.paddingContent}>
          <Avatar
            className={styles.felosubtitleavatar}
            style={{ backgroundColor: user?.picture?.indexOf('http') >= 0 ? 'transparent' : 'rgb(36, 141, 247)' }}
            size={36}
            src={user?.picture?.indexOf('http') >= 0 ? user.picture : null}
          >
            {user?.email?.charAt(0).toLocaleUpperCase()}
          </Avatar>
          <div className={styles.useRight}>
            <div className={styles.useName} title={user?.email}>
              {user?.email}
            </div>
            <div className={styles.balance}>
              <div className={styles.balanceText}>{t('balance')}</div>
              <div className={styles.balanceText}>{balance}</div>
            </div>
          </div>
          <div className={styles.topUpbotton} onClick={(e) => openPricingpage(e)}>
            {t('topUp')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
