import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routers from './Routes';
import { AntdProvider } from './components/base/AntdProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <AntdProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </QueryClientProvider>
    </AntdProvider>
  );
}

export default App;
