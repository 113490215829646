import { useState, useEffect, useRef, useMemo } from 'react';
import { Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getLogDetail, getShareRoomId } from '@/api/subtitles';
import css from './index.module.less';
import useShareMqtt from '@/hooks/useShareMqtt';
import { LoadingOutlined } from '@ant-design/icons';
import logoIcon from '@/assets/images/translatorLogo.svg';
// import useSubtitleMqtt from '@/hooks/useSubtitleMqtt';

interface ShareSubtitleItem {
  subtitleId: string;
  translateText?: string;
  translateLang?: string;
  originalText?: string;
  originalLang?: string;
  resultId?: string;
}

const originalResultMap: Record<string, { originalText: string; originalLang: string; resultId: string }> = {};

function Share() {
  const { t } = useTranslation();

  const { id: uid } = useParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const [subtitleList, setSubtitleList] = useState<ShareSubtitleItem[]>([]);
  console.log('>> uid', uid);

  useEffect(() => {
    if (!isManualScroll) {
      scrollToBottom();
    }
  }, [subtitleList, isManualScroll]);
  // 滚动到底部的函数
  const scrollToBottom = () => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  };

  const { data: shareData, isLoading: shareDataLoading, isError: shareDataError } = useQuery({
    queryKey: ['getShareRoomId'],
    queryFn: () => {
      return getShareRoomId(uid).then((res) => {
        console.log('>> res', res);
        return res.data.data;
      });
    },
    retry: 2,
  });
  const [shareRoomId, subtitleSegmentId] = useMemo(() => {
    if (!shareData) {
      return [];
    }

    return [shareData.room_id, shareData.user_subtitle_segment_id];
  }, [shareData]);

  const { data: logDetailData, isLoading: logDetailLoading, isError: logDetailError, refetch: logDetailRefetch } = useQuery({
    queryKey: ['getLogDetail', subtitleSegmentId],
    queryFn: () => {
      if (!subtitleSegmentId) {
        return Promise.resolve(null);
      }
      return getLogDetail(subtitleSegmentId).then((res) => {
        return res.data.data;
      });
    },
    enabled: !!subtitleSegmentId,
  });

  const [title, shareStatus]: [string, string] = useMemo(() => {
    if (!logDetailData) {
      return ['', 'unknown'];
    }

    setSubtitleList(logDetailData.subtitle_list.map((item) => {
      return {
        subtitleId: item.subtitle_id,
        translateLang: item.subtitle_translation_list?.[0].lang || '',
        translateText: item.subtitle_translation_list?.[0].text || '',
        originalText: item.text,
      };
    }));

    return [logDetailData.title, logDetailData.status]

  }, [logDetailData]);


  useShareMqtt({
    roomId: shareRoomId,
    onMessage: (msg: API.TranslateList) => {
      console.log('>> onMessage', msg.data);
      if (msg.type === 'MEET_SUBTITLE_TEXT') {
        originalResultMap[msg.data.resultId] = {
          originalText: msg.data.originalText,
          originalLang: msg.data.originalLang,
          resultId: msg.data.resultId,
        };
        return;
      }
      setSubtitleList((prevData: ShareSubtitleItem[]) => {
        const nextData = [...prevData];
        const index = nextData.findIndex((item) => item.subtitleId === msg.data.subtitleId);

        if (index > -1) {
          nextData[index] = {
            ...nextData[index],
            translateText: msg.data.translateText,
            translateLang: msg.data.translateLang,
          };
        } else {
          const originalResult = originalResultMap[msg.data.resultId] || {};
          delete originalResultMap[msg.data.resultId];
          nextData.push({
            ...originalResult,
            subtitleId: msg.data.subtitleId,
            translateText: msg.data.translateText,
            translateLang: msg.data.translateLang,
          });
        }
        return nextData;
      });
    },
    onLoaded: () => {
      console.log('>> onLoaded');
      logDetailRefetch();
    },
  });

  const handleWheel = (event:any) => {
    if (event.deltaY > 0) {
      const scroll = scrollRef.current;
      if (scroll) {
        const isUserScrollUp = scroll.scrollTop < (scroll.scrollHeight - scroll.offsetHeight-2);
        setIsManualScroll(isUserScrollUp);
      }
    } else if (event.deltaY < 0) {
      setIsManualScroll(true);
    }
  };

  if (shareDataLoading || logDetailLoading) {
    return (
      <div className={css.shareContainer}>
        <div className={css.share}>
          <div className={css.pageTitle}>
            <div className={css.largeLogo}>
              <img src={logoIcon} alt="Felo Logo" />
              <span>{t('menuSubtitles')}</span>
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (shareDataError || logDetailError) {
    return (
      <div className={css.shareContainer}>
        <div className={css.share}>
          <div className={css.pageTitle}>

          <div className={css.titleText}>
            <h3>{title}</h3>
          </div>
          <div className={css.logo}>
            <img src={logoIcon} alt="Felo Logo" />
            <span>{t('menuSubtitles')}</span>
          </div>
        </div>

          <div className="flex items-center justify-center flex-1">
            <span className="text-red-500">{t('share.error')}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.shareContainer}>
    <div className={css.share}>
      <div className={css.pageTitle}>
          <div className={css.titleText}>
            <h3>{title}</h3>
            <div className={clsx(css.status, css[shareStatus])} role="button" tabIndex={0}>
              <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.286" y="1" width="4.571" height="7.429" rx="2.286" fill="currentColor" stroke="currentColor" strokeWidth=".75"></rect>
                <path d="M10.143 6.429a4.571 4.571 0 0 1-9.143 0" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"></path>
              </svg>
              {t(`share.status.${shareStatus}`)}
            </div>
          </div>
          <div className={css.logo}>
            <img src={logoIcon} alt="Felo Logo" />
            <span>{t('menuSubtitles')}</span>
          </div>
        </div>
      <div className={css.subtitlesdetail} ref={scrollRef} onWheel={handleWheel}>
        {logDetailLoading ? (
          <main className={css.body}>
            <Spin className="!block mx-auto" size="large" />
          </main>
        ) : (
          <main className={css.body}>
            <div className={css.desc}>
              <div className={css.timeFromWrapper}>
                <span className={css.time}>
                  {dayjs(logDetailData?.gmt_create).format('YYYY-MM-DD HH:mm:ss')}
                </span>
                <em className={clsx(css.from, logDetailData?.source && css[logDetailData.source.toLocaleLowerCase().replace('_', '-')])}>
                  {logDetailData?.source.replace('_', ' ')}
                </em>
              </div>
              <a className={css.url} href={logDetailData?.source_url} target="_blank">
                {logDetailData?.source_url}
              </a>
            </div>
            <article >
              {subtitleList.map((item, index) => {
                return (
                  <div key={index} className={css.subtitleItem}>
                    <span className={css.original}>{item.originalText}</span>
                    <span className={css.translate}>→ {item.translateText}</span>
                  </div>
                );
              })}
            </article>
          </main>
        )}
      </div>
    </div>
    </div>
  );
}

export default Share;
